import React from "react"
import { Link } from "gatsby"
import { Button } from "antd"


const WelcomePage = () => {
  return (
    <div>
      welcome
      <Button><Link to={"/sign-up"}>Registrati</Link></Button>
      <Button><Link to={"/sign-in"}>Login</Link></Button>
    </div>
  )
}

export default WelcomePage
